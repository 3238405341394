<template>
  <div>
    <div class="toolbar">
      I have an account!&nbsp;
      <v-btn small dark color="primary" to="/login">
        Login
      </v-btn>
    </div>

    <v-form
      ref="form"
      v-model="form.valid"
      lazy-validation>
      <v-card elevation="0" class="mx-10 px-10" color="transparent">
        <v-card-text>
          <a href="/" plain text inline>
            <v-logo :show-big="true" width="200px"></v-logo>
          </a>
          <h2 class="text-h4 text-center">Register an Account</h2>
        </v-card-text>
        <v-card-text>
          <v-container fluid>
            <v-row dense>
              <v-col class="text-center">
                <v-alert dense outlined color="primary">
                  You will get your first <b>5 credits</b> for <b>FREE</b><br>to test drive Hounddog and generate reports.
                </v-alert>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  background-color="rgba(255, 255, 255, 0.75)"
                  v-model="form.fields.name"
                  :rules="[$utilities.rules.required]"
                  label="Full Name"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  background-color="rgba(255, 255, 255, 0.75)"
                  type="email"
                  v-model="form.fields.email"
                  :rules="[$utilities.rules.required, $utilities.rules.isEmail]"
                  label="Email"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- <v-row dense>
              <v-col>
                <v-text-field
                  background-color="rgba(255, 255, 255, 0.75)"
                  type="password"
                  v-model="form.fields.password"
                  :rules="[$utilities.rules.required, $utilities.rules.min]"
                  label="Password"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  background-color="rgba(255, 255, 255, 0.75)"
                  type="password"
                  v-model="form.fields.confirm"
                  :rules="[$utilities.rules.required, $utilities.rules.min, rules.mustMatch]"
                  label="Confirm"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row> -->
            <v-row dense>
              <v-col>
                <v-btn x-large color="primary" dark @click="register">
                  Register
                </v-btn>
              </v-col>
              <v-col cols="7">
                Check your email for a confirmation link and to set your password.
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
  export default {
    name: 'Register.vue',
    data() {
      return {
        rules: {
          mustMatch: v => this.form.fields.password === v || 'Passwords must match',
        },
        form:{
          valid: false,
          fields: {
            name:'',
            email: '',
            password: '',
            confirm: '',
          },
        },
      }
    },
    methods:{
      async register(){
        let self = this;
        if(this.$refs.form.validate()){
          await this.$store.dispatch("users/register", this.form.fields)
            .then((result) => {
              if(result){
                self.$router.push('/login');
              }
            })
        }
      },
    },
  }
</script>

<style lang="scss">

</style>
